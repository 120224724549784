.categories{
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
    padding: 40px 80px;
    gap:32px;
    box-sizing: border-box;
}

.categories>h2{
    font-size: 32px;
    font-weight: 300;
    color: #DA6220;
    font-family: 'Yantramarav' , sans-serif;
    margin:0;
}

.categories>input{
    width: 100%;
    height: 36px;
    border: none;
    background-color: #F2F2F2;
    border-radius: 10px;
    padding: 0 16px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 300;
}

.categories>.main-pannel{
    display: flex;
    flex-wrap: wrap;
    gap: 80px;
    width: 100%;
    height: 100%;
    margin-top: 32px;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: flex-start;
}


.categories>.main-pannel>.add-category-button{
    cursor: pointer;
    width:260px;
    height: 304px;
    background-color: transparent;
    border: 2px dashed #DA6220;
    color: #DA6220;
    display: flex;
    flex-direction: column;
    padding:56px 0;
    align-items: center;
    justify-content: space-between;
}
.categories>.main-pannel>.add-category-button>p{
    font-size: 16px;
    font-weight: 700;
    margin:0;
}
.categories>.main-pannel>.add-category-button>span{
    font-size: 120px;
    font-weight: 100;
    margin:0;
}

.category-card{
    height: 304px;
    width:260px;
    cursor: pointer;
}
.category-card>img{
    filter: brightness(0.5);
    height: 100%;
    width: 100%;
}

.category-card>p{
    position: relative;
    margin:0;
    top: -80px;
    left:0;
    font-weight: 400;
    font-size: 16px;
    margin:0;
    line-height: 32px;
    color: white;
    background-color: #DA6220;
    text-align: center;
}

.category-card:hover{
    transition: 0.2s ease-in-out;
    /*Drop shadow*/
    box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.25);
}

.categories>.main-pannel>.add-category-button:hover{
    border: 2px solid #DA6220;
    transition : 0.2s ease-in-out;
    box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.25);
}