.side-pannel{
    background-color: #DA6220;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:32px;
    padding: 24px 32px;
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    flex-basis: 0;
}

.side-pannel>img{
    height: 160px;
    width: 160px;
    cursor: pointer;
}
.side-pannel>div{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 32px;
    width: 100%;
}

.side-pannel>div>a{
    text-decoration: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
}

.active{
    font-weight: 700;
}



.side-pannel>div>a:hover{
    font-weight: 700;
}