html,body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}
.App{
  height: 100vh;
  display: flex;
  flex-direction: row;
  padding: 0;
}
.content{
  width: 100%;
  height: 100%;
  overflow: auto;
}
