.modal{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
}
.modal-content{
    width: 80vw;
    height: 80vh;
    background-color: white;
    border-radius:56px;
    padding: 56px;
    position: relative;
    box-sizing: border-box;
}

.close-modal-button{
    color: #DA6220;
    font-size: 80px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 100;
    background-color: transparent;
    border:none;
    cursor: pointer;
    position:absolute;
    top:0;
    right:0;
    width:100px;
    height:100px;
    padding:0
}
.close-modal-button:hover{
    color: #F2A154;
    transition: 0.1s ease-in-out;
}
.modal-content>h3{
    font-size: 32px;
    font-weight: 100;
    color: #DA6220;
    margin:0;
}

.category-form{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    align-items: center;
}

.input-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

button[type="submit"]{
    width: 224px;
    height: 60px;
    background-color: #DA6220;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 24px;
    font-weight: 100;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    justify-self: flex-end;
}

.input-wrapper label{
    font-size: 24px;
    font-weight: 100;
    color: #0E5A86;
    margin-bottom: 8px;
}

input[type="text"], select,input[type="number"]{
    width: 100%;
    height: 60px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 8px;
    font-size: 24px;
    font-weight: 100;
    box-sizing: border-box;
}

.photo-input-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 32px;
}

.photo-preview>img{
    width: 220px;
    height: 220px;
    cursor: pointer;
}