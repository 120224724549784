.modal{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
}
.modal-content{
    background-color: white;
    border-radius:56px;
    padding: 56px;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
}

.close-modal-button{
    color: #DA6220;
    font-size: 80px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 100;
    background-color: transparent;
    border:none;
    cursor: pointer;
    position:absolute;
    top:0;
    right:0;
    width:100px;
    height:100px;
    padding:0
}
.close-modal-button:hover{
    color: #F2A154;
    transition: 0.1s ease-in-out;
}
.modal-content>h3{
    font-size: 32px;
    font-weight: 100;
    color: #DA6220;
    margin:0;
}
.product-modal>.modal-content{
    width: 95vw;
    height: 95vh;
}

.product-form{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    align-items: center;
}

.sides-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 40px 60px;
    gap:80px;
}
.side{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.input-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

button[type="submit"]{
    width: 224px;
    height: 60px;
    min-height: 60px;
    background-color: #DA6220;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 24px;
    font-weight: 100;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    justify-self: flex-end;
    margin-bottom: 40px;
}

label{
    font-size: 24px;
    font-weight: 100;
    color: #0E5A86;
    margin-bottom: 8px;
}

input[type="text"], select, input[type="color"]{
    width: 100%;
    height: 60px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 8px;
    font-size: 24px;
    font-weight: 100;
    box-sizing: border-box;
}
input[type="color"]{
    padding:0;
}

textarea{
    width: 100%;
    max-width: 100%;
    height: 200px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 8px;
    font-size: 24px;
    font-weight: 100;
    box-sizing: border-box;
}

.photos-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap:120px;
}

.photo-wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
.upload-photo-button{
    cursor: pointer;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    padding:0;
    align-items: center;
    justify-content: center;
    font-size: 80px;
    font-weight: 100;
    margin:0;
    color:black;
}

.main-upload-photo, .variant-main-upload-photo{
    width:160px;
    object-fit: contain;
}
.main-upload-photo>img, .variant-main-upload-photo>img{
    width:100%;
    height:100%;
    border-radius: 5px;
    margin:0;
    padding: 0;
}

.secondary-upload-photo{
    width:100px;
    height:100px;
}

.secondary-photos-conatainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
}
.secondary-photos-conatainer>div>label>img, .product_secondary_photos>div>label>img{
    width:100px;
    height:100px;
    object-fit: contain;
    border-radius: 5px;
}

.secondary-upload-photos{
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;
    

}


.delete-button{
    position: absolute;
    right: 150px;
    top : 32px;
    background-color: red;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 24px;
    font-weight: 100;
    cursor: pointer;
}

.variant-button{
    min-height: 60px;
    background-color: #DA6220;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    padding:8px;
    font-weight: 100;
    cursor: pointer;
    justify-self: flex-end;
    margin-bottom: 40px;
}

#variant_color>option:hover{
    background-color: #DA6220;
    color: white;
}

.variant-input-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 40px;
}
.variant-input-row>.photos-wrapper>.photo-wrapper>label>img{
    height: 60px;
    object-fit: contain;
    gap: 24px;
}

.variant-modal>.modal-content>form>.variant-input-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap:40px;
}
.variant-modal>.modal-content>form{
    justify-content: flex-start;
    margin-top:40px;
    gap:40px;
}
.add-color-variant-button,.remove-variant-input-row{
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    color:#DA6220;
    font-weight: 700;
}
.add-color-variant-button:hover,.remove-variant-input-row:hover{
    color:#F2A154;
    transition: 0.1s ease-in-out;
}

.remove-variant-input-row{
    font-size: 24px;
}
.button-wrapper{
    flex-direction: row !important;
    justify-content: space-around !important;
    align-items: center !important;
}